<template>
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#a)" :fill="fillColor">
        <path d="M19.5 1.5H6A1.5 1.5 0 0 0 4.5 3v3H3v1.5h1.5v3.75H3v1.5h1.5v3.75H3V18h1.5v3A1.5 1.5 0 0 0 6 22.5h13.5A1.5 1.5 0 0 0 21 21V3a1.5 1.5 0 0 0-1.5-1.5Zm0 19.5H6v-3h1.5v-1.5H6v-3.75h1.5v-1.5H6V7.5h1.5V6H6V3h13.5v18Z"/>
        <path d="M16.5 6h-6v1.5h6V6ZM16.5 11.25h-6v1.5h6v-1.5ZM16.5 16.5h-6V18h6v-1.5Z"/>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z"/>
        </clipPath>
      </defs>
  </svg>
</template>

<script>
export default {
  name: 'OrdersIcon',

  props: {
    fillColor: {
      type: String,
      default: '#FCFCFC',
    }
  }
}
</script>
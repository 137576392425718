<template>
  <div class="footer d-flex flex-column align-content-between">
    <div class="footer-hero">
      <div class="texts">
        <h6 class="title">Right in your pocket.</h6>
        <p class="description">
          Keeping up with schedules and budgets is a task. When your team is on
          pocketyard, you have everything you need to maintain construction
          progress.
        </p>
        <button class="btn explore" @click="$router.push('register')">Explore now</button>
      </div>
    </div>
    <div class="footer-links d-flex flex-column align-content-between">
      <div class="top d-flex justify-content-between flex-column flex-lg-row">
        <div class="left">
          <div class="logo">
            <img alt="Logo" title="Logo" src="@/assets/pocketyard_logo.svg" />
          </div>
          <div class="address">
            <p>Moda Holdings LLC.</p>
            <p>3177 37th Street, Astoria, NY 11103</p>
            <p>United States</p>
          </div>
        </div>
        <div class="right">
          <div class="columns d-none d-md-flex justify-content-between">
            <div class="item company d-flex flex-column">
              <div class="title">Company</div>
              <a class="link" @click="toHome" :class="activeItem === 'homepage' ? 'active' : ''">Home</a>
            </div>
            <div class="item supply d-flex flex-column">
              <div class="title">Supply</div>
              <a class="link" @click="toVendor" :class="activeItem === 'vendors' ? 'active' : ''">Vendor</a>
              <a class="link" @click="toBuyer" :class="activeItem === 'buyers' ? 'active' : ''">Buyer</a>
            </div>
            <div class="item socmed d-flex flex-column">
              <div class="title">Social Media</div>
              <a href="https://facebook.com/pocketyard" class="link"><img class="icon"
                  src="@/assets/Landing/facebook.svg" />Facebook</a>
              <a href="https://www.linkedin.com/company/pocketyard/" class="link"><img class="icon"
                  src="@/assets/Landing/linkedin.svg" />LinkedIn</a>
              <a href="https://www.instagram.com/pocketyard_/" class="link"><img class="icon"
                  src="@/assets/Landing/instagram.svg" />Instagram</a>
            </div>
            <div class="item support d-flex flex-column">
              <div class="title">Support</div>
              <a href="mailto:info@pocketyard.com" class="link">
                info@pocketyard.com
              </a>
              <a href="tel:+17183090635" class="link">+1 7183090635</a>
            </div>
          </div>
          <!-- Mobile version -->
          <div class="columns d-flex d-md-none flex-column w-100">
            <div class="half d-flex justify-content-between">
              <div class="w-50 item company d-flex flex-column">
                <div class="title">Company</div>
                <a class="link" @click="toHome" :class="activeItem === 'homepage' ? 'active' : ''">Home</a>
              </div>
              <div class="w-50 item supply d-flex flex-column">
                <div class="title">Supply</div>
                <a class="link" @click="toVendor" :class="activeItem === 'vendors' ? 'active' : ''">Vendor</a>
                <a class="link" @click="toBuyer" :class="activeItem === 'buyers' ? 'active' : ''">Buyer</a>
              </div>
            </div>
            <div class="half d-flex justify-content-between">
              <div class="w-50 item socmed d-flex flex-column">
                <div class="title">Social Media</div>
                <a href="fb://page/101952265086346" class="link">
                  <img class="icon" src="@/assets/Landing/facebook.svg" />
                  Facebook
                </a>
                <a href="https://www.linkedin.com/company/pocketyard/" class="link">
                  <img class="icon" src="@/assets/Landing/linkedin.svg" />
                  LinkedIn
                </a>
                <a href="https://www.instagram.com/pocketyard_/" class="link">
                  <img class="icon" src="@/assets/Landing/instagram.svg" />
                  Instagram
                </a>
              </div>
              <div class="w-50 item support d-flex flex-column">
                <div class="title">Support</div>
                <a href="mailto:info@pocketyard.com" class="link">info@pocketyard.com</a>
                <a href="tel:+17183090635" class="link">+1 7183090635</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="d-flex justify-content-between flex-column flex-md-row">
          <p>©️ Pocketyard 2023. All rights reserved</p>
          <div class="tos d-flex">
            <router-link :to="{ name: 'TermsAndConditions' }">Terms & Conditions</router-link>
            <div class="divider">|</div>
            <router-link :to="{ name: 'PrivacyPolicy' }">Privacy Policy</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingFooter',
  props: {
    activeItem: {
      type: String,
      default: 'buyers',
    },
  },
  methods: {
    scrollToTop() {
      document.getElementById('navbar').scrollIntoView({ behavior: 'smooth' });
    },
    toBuyer() {
      if (this.activeItem === 'buyers') {
        this.scrollToTop();
        return true;
      }
      this.$router.push({ name: 'Buyer' });
    },
    toVendor() {
      if (this.activeItem === 'vendors') {
        this.scrollToTop();
        return true;
      }
      this.$router.push({ name: 'Vendor' });
    },
    toHome() {
      if (this.activeItem === 'homepage') {
        this.scrollToTop();
        return true;
      }
      this.$router.push({ name: 'Landing' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_breakpoints';
.footer {
  .footer-hero {
    background: url(~@/assets/Landing/footer-image.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 47.79vh;
    padding: 11.4vh 29vw;
    position: relative;
    text-align: center;
    letter-spacing: 0.2px;

    @include media-md('below') {
      background: url(~@/assets/Landing/mobile-footer.png);
      height: 55vh;
      text-align: left;
      padding: 60px 15px;
    }

    .title {
      text-transform: uppercase;
      font-family: Oswald;
      font-weight: 700;
      font-size: 40px;
      line-height: 60px;
      margin-bottom: 32px;

      @include media-md('below') {
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.2px;
      }
    }

    .description {
      font-family: Inter;
      font-weight: 500;
      font-size: 18px;
      line-height: 28.8px;
      margin-bottom: 32px;

      @include media-md('below') {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.2px;
      }
    }

    .explore {
      padding: 17.5px 52px;
      background-color: #F38725;
      text-transform: uppercase;
      font-family: Inter;
      font-weight: 600;
      font-size: 18px;
      line-height: 25.2px;

      @include media-md('below') {
        color: white;
        padding: 13px 37px;
      }

      &:hover {
        background-color: #E67200;
        box-shadow: 0px 4px 8px 0px #3031331A;
        box-shadow: 0px 0px 1px 0px #3031330D;
      }
    }
  }

  .footer-links {
    height: 60vh;
    background-color: #282828;
    padding: 0px 8.59375vw;
    padding-top: 10.27vh;
    padding-bottom: 6.68vh;

    color: #F1F1F1;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    margin-bottom: 0px;

    @include media-lg('below') {
      padding-top: 5vh;
    }

    @include media-md('below') {
      height: auto;
    }

    a {
      color: #F1F1F1;
      margin-bottom: 0px;
      text-decoration: none;

      &:hover {
        color: #F38725;
        cursor: pointer;
      }
    }

    .top {
      font-size: 15px;
      font-weight: 400;
      margin-bottom: auto;
      padding-right: 3.75vw;

      .left {
        @include media-md('below') {
          p {
            font-size: 12px;
            line-height: 22px;
            letter-spacing: 0em;
          }

          .address {
            margin-bottom: 40px;
          }
        }

        p {
          margin-bottom: 8px;
        }

        .logo {
          margin-bottom: 4.11vh;
        }
      }

      .right {
        .active {
          color: #F38725;
        }
        width: 42.65vw;
        padding-top: 4.933vh;
        padding-bottom: 4.933vh;

        @include media-md('below') {
          width: 100%;
          padding-top: 0px;
          padding-bottom: 0px;

          .half {
            margin-bottom: 40px;
          }

          a,
          p {
            font-size: 13px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
          }
        }

        .title {
          color: #F9C392;
          margin-bottom: 24px;
          font-size: 16px;
          font-weight: 400;

          @include media-md('below') {
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0em;
          }
        }

        .link {
          margin-bottom: 24px;
        }

        .icon {
          margin-right: 16px;
        }
      }
    }

    .bottom {
      border-top: 1px solid #FFFFFF4D;
      padding-top: 4.11vh;

      .divider {
        padding-left: 16px;
        padding-right: 16px;
        color: #FFFFFF4D;
      }

      .tos {

        a,
        p {
          color: #CECECE;
          text-align: center;

          &:hover {
            color: #F38725;
          }
        }
      }

      @include media-md('below') {
        text-align: center;
        .divider {
          padding-left: 8px;
          padding-right: 8px;
        }
        .tos {
          margin-left: auto;
          margin-right: auto;
        }
        p {
          margin-bottom: 8px;
        }
      }
    }
  }
}
</style>
